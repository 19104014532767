import React, { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BaseImgUrl } from '../../Config';
import Rating from 'react-rating';
// import OtpInput from "react-otp-input";
import SelectAvtarPopup from './SelectAvtarPopup';
import toast from 'react-hot-toast';



const ProductReviewPopup = ({ showProductReviewPopup, setShowProductReviewPopup }) => {

  const [customerReview, setCustomerReview] = useState({
    profile: '',
    name: '',
    rating: '',
    review: '',
    attachment: [],
    number: '',
    otp: '',
  });

  const [showAvtarPopup, setShowAvtarPopup] = useState(false);

  // To handle change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setCustomerReview(preState => ({
      ...preState,
      [name]: value
    }));
  };
  
  // To handle name
  const handleRating = (value) => {
    setCustomerReview(preState => ({
      ...preState,
      rating: value
    }));
  };

  // To select only 3 attachment 
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    if (files.length > 3) {
      alert('You can only select up to 3 attachments.');
      event.target.value = ''; // Clear the input
    } else {
      setCustomerReview((prevReview) => ({
        ...prevReview,
        attachment: files,
      }));
    };
  };

  // To handle submit
  const handleSubmit = () => {
    setCustomerReview({
      profile: '',
      name: '',
      rating: '',
      review: '',
      attachment: [],
      number: '',
      otp: '',
    });

    setShowProductReviewPopup(false);

    toast.success(`Thanks for your review.`);
  };

  return (
    <>
      {/* Avtar Popup */}
      <SelectAvtarPopup showAvtarPopup={showAvtarPopup} setShowAvtarPopup={setShowAvtarPopup} setShowProductReviewPopup={setShowProductReviewPopup} setCustomerReview={setCustomerReview} />
      

      <Modal show={showProductReviewPopup} onHide={() => setShowProductReviewPopup(false)} centered keyboard={false} className="" >
        <Modal.Header closeButton className='border-bottom-0'>
          <h4 className='mx-auto'>Product Review</h4>
        </Modal.Header>

        <Modal.Body className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '75vh' }}>
          <section className='d-flex flex-column'>
            {/* Profile */}
            <div className='text-center mb-4 position-relative'>
              {
                customerReview.profile
                ?
               <img src={customerReview.profile} className='rounded-circle shadow object-fit-cover' style={{ height: '125px', width: '125px' }} alt="profile" />
               :
               <img src={`${BaseImgUrl}/avtar/dummy-avtar.png`} className='rounded-circle shadow object-fit-cover' style={{ height: '125px', width: '125px' }} alt="profile" />
              }

              <img src={`${BaseImgUrl}/icons/camera.svg`} onClick={() => {setShowAvtarPopup(true); setShowProductReviewPopup(false)}} className='border bg-secondary-subtle p-1 rounded-circle position-absolute top-100 start-50 translate-middle' style={{ width: '30px' }} alt="camera" />
            </div>

            {/* Rating */}
            <Rating
              className='text-center mt-3 mb-3'
              initialRating={customerReview.rating}
              onChange={handleRating}
              emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '23px' }} alt="empty" />}
              fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '23px' }} alt="fill" />}
            />

            {/* Name */}
            <div className="form-floating mb-3">
              <input type="text" className="form-control text-capitalize" value={customerReview.name} onChange={handleChange} name='name' placeholder="Name..." />
              <label htmlFor="floatingInput">Name</label>
            </div>

            {/* Review */}
            <div className="form-floating mb-3">
              <textarea className="form-control" value={customerReview.review} onChange={handleChange} name='review' placeholder="Leave a comment here" style={{ height: '100px' }}></textarea>
              <label htmlFor="floatingTextarea">Write your review here...</label>
            </div>
          </section> 

          {/* Attachment */}
          <input type="file" id='productReviewAttachment' className="form-control mb-3" ref={fileInputRef} onChange={handleFileChange} multiple />

          {/* Selected */}
          <div className='mb-3 d-flex'>
            {
              customerReview.attachment.length > 0
              &&
              customerReview.attachment.map((photo, index) => (
                <div className='me-3'>
                  <img src={URL.createObjectURL(photo)} className='rounded-3 shadow-sm' style={{ width: '77px' }} alt="attachment" key={index} />
                </div>
              ))
            }
          </div>

          {/* Number */}
          <div className='mb-3'>
            <div className="form-floating me-3">
              <input type="tel" className="form-control" value={customerReview.number} onChange={handleChange} name='number' pattern='[0-9]{10}' placeholder="Name..." />
              <label htmlFor="floatingInput">Number</label>
            </div>
          </div>

          <div className='text-center'>
            <button type="button" className='btn btn-primary w-50' onClick={handleSubmit}>Submit</button>
          </div>
        </Modal.Body>
      </Modal> 
    </>
  )
}

export default ProductReviewPopup
