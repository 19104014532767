import React, { useContext, useEffect } from 'react';

import HomeCategories from './HomeCategories';
import FeaturedItems from './FeaturedItems';
import HomeProducts from './HomeProducts';
import CustomersReviews from './CustomersReviews';
import Certificates from './Certificates';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import { BaseImgUrl } from '../../Config';
import { UserContext } from '../UseContext/NavkarContext';



const Home = () => {

  const homeBanner = [
    `${BaseImgUrl}/home-banner-1.jpg`,
    `${BaseImgUrl}/home-banner-2.jpg`,
    `https://dostguru.com/HTML/flavoro/assets/img/banr3.jpg`,
    `https://dostguru.com/HTML/flavoro/assets/img/blog/blog_1.jpg`,
    `https://dostguru.com/HTML/flavoro/assets/img/blog/blog_3.jpg`,
  ];

  const { getCustomerReviews } = useContext(UserContext);

  useEffect(() => {
    getCustomerReviews();
  }, []);

  return (
    <>
      <section className=''>
        {/* Ads */}
        <Swiper
          className="mySwiper banner"
          loop={true}
          navigation={true}
          pagination={{
            dynamicBullets: true,
          }}
          keyboard={true}
          slidesPerView={1}
          modules={[Navigation, Pagination, Keyboard]}
        >
          {
            homeBanner.map((banner, index) => (
              <SwiperSlide key={index}><img src={banner} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
            ))
          }
          {/* <SwiperSlide><img src={``} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={``} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={``} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={``} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide> */}
        </Swiper>

        {/* Categories */}
        <HomeCategories />

        {/* Featured Items */}
        <FeaturedItems />

        {/* Ads  */}
        <img src={`${BaseImgUrl}/home-ads-1.png`} className='w-100' alt="ads" />

        {/* Products */}
        <HomeProducts />

        {/* Ads */}
        <div className='d-flex justify-content-evenly'>
          <img src={`${BaseImgUrl}/home-ads-2.png`} className='home-ads-2' alt="ads" />
          <img src={`${BaseImgUrl}/home-ads-3.png`} className='home-ads-2' alt="ads" />
        </div>

        {/* Customers Reviews */}
        <CustomersReviews />

        {/* Certificates */}
        <Certificates />
      </section>
    </>
  )
}

export default Home
