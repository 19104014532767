import React, { useContext } from 'react';
import Rating from 'react-rating';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import { BaseImgUrl } from '../../Config';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UseContext/NavkarContext';



const Products = () => {

  const navigate = useNavigate();

  const { navkar } = useContext(UserContext);

  const products = [
    {
      image: `${BaseImgUrl}/aachar-1.png`,
      name: 'Aam Ka Aachar',
      rating: 3.5,
      description: 'Aam Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '100 gm',
      size2: '200 gm',
      price1: '120',
      price2: '150',
    },
    {
      image: `${BaseImgUrl}/aachar-2.png`,
      name: 'Jam Ka Aachar',
      rating: 4,
      description: 'Nimbu Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '150 gm',
      size2: '250 gm',
      price1: '150',
      price2: '200',
    },
    {
      image: `${BaseImgUrl}/aachar-3.png`,
      name: 'Wam Ka Aachar',
      rating: 2.5,
      description: 'Aawla Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '175 gm',
      size2: '225 gm',
      price1: '175',
      price2: '250',
    },
    {
      image: `${BaseImgUrl}/aachar-4.png`,
      name: 'Kam Ka Aachar',
      rating: 4.5,
      description: 'Aam Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '100 gm',
      size2: '200 gm',
      price1: '120',
      price2: '150',
    },
    {
      image: `${BaseImgUrl}/aachar-5.png`,
      name: 'Nam Ka Aachar',
      rating: 3,
      description: 'Nimbu Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '150 gm',
      size2: '250 gm',
      price1: '150',
      price2: '200',
    },
    {
      image: `${BaseImgUrl}/aachar-1.png`,
      name: 'Aam Ka Aachar',
      rating: 3.5,
      description: 'Aam Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '100 gm',
      size2: '200 gm',
      price1: '120',
      price2: '150',
    },
    {
      image: `${BaseImgUrl}/aachar-2.png`,
      name: 'Jam Ka Aachar',
      rating: 4,
      description: 'Nimbu Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '150 gm',
      size2: '250 gm',
      price1: '150',
      price2: '200',
    },
    {
      image: `${BaseImgUrl}/aachar-3.png`,
      name: 'Wam Ka Aachar',
      rating: 2.5,
      description: 'Aawla Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '175 gm',
      size2: '225 gm',
      price1: '175',
      price2: '250',
    },
    {
      image: `${BaseImgUrl}/aachar-4.png`,
      name: 'Kam Ka Aachar',
      rating: 4.5,
      description: 'Aam Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '100 gm',
      size2: '200 gm',
      price1: '120',
      price2: '150',
    },
    {
      image: `${BaseImgUrl}/aachar-5.png`,
      name: 'Nam Ka Aachar',
      rating: 3,
      description: 'Nimbu Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
      size1: '150 gm',
      size2: '250 gm',
      price1: '150',
      price2: '200',
    },
  ];

  return (
    <>
      <section>
        {/* Ads */}
        <Swiper 
          className="mySwiper products-banner"
          loop={true}
          modules={[Navigation, Pagination, Keyboard]} 
          navigation={true} 
          pagination={{
            dynamicBullets: true,
          }}
          keyboard={true}
          slidesPerView={1}
        >
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/s1.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/s2.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/banr3.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/blog/blog_1.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/blog/blog_3.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
        </Swiper>

        <div className='mt-3 mt-md-4 mt-lg-5'>
          {/* Header */}
          <div className='d-flex flex-column align-items-center my-4 my-md-5'>
            <h3 className='home-head-1 mb-0'>Products</h3>
            <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
            <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
          </div>

          {/* Products 1 */}
          <div className='products1 my-5'>
            {products.map((product, index) => (
              <div key={index} className='products1-product bg-white rounded-4 p-2 shadow-sm' onClick={() => navigate(`/product`)}>
                <img src={product.image} className='w-100' alt="aachar" />

                <div className='fs-6 fw-semibold'>{product.name}</div>

                {/* Rating */}
                <Rating
                  className=''
                  initialRating={product.rating}
                  readonly
                  emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1'  style={{ height: '13px' }} alt="empty" />}
                  fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '13px' }} alt="fill" />}
                />

                <small className='d-block my-1'>Lorem ipsum dolor sit amet, consectetur elit.</small>
                
                {/* Size */}
                <div>
                  <span key={index} className='badge bg-warning text-white me-1 rounded-pill fw-normal'>{product.size1}</span>
                  {/* <span key={index} className='badge border border-warning text-warning me-1 rounded-pill fw-normal'>{product.size2}</span> */}
                </div>

                {/* Price */}
                <div className='my-2'>
                  <span className='fs-5 fw-semibold me-sm-3' style={{ marginRight: '3px' }}>₹120.00</span>
                  <span className='text-decoration-line-through text-secondary'>₹150.00</span>
                </div>

                {/* Order or Call */}
                <div className='d-flex justify-content-evenly mb-2'>
                  {/* Order */}
                  <div className='border border-success rounded-pill badge d-flex align-items-center w-50 justify-content-center me-2 py-2'
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      window.location.href = `https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`;
                    }}
                  >
                    <img src={`${BaseImgUrl}/icons/product-order.svg`} alt="order" />
                    <span className='text-success fw-normal ms-1 ms-sm-2'>Order</span>
                  </div>

                  {/* Call */}
                  <div className='bg-success d-inline rounded-pill badge d-flex align-items-center w-50 justify-content-center py-2'
                    onClick={(e) => { 
                      e.stopPropagation(); 
                      window.location.href = `tel:${navkar.number}`;
                    }}
                  >
                    <img src={`${BaseImgUrl}/icons/product-call.svg`} alt="call" />
                    <span className='text-white fw-normal ms-1 ms-sm-2'>Call</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> 
    </>
  )
}

export default Products;
