import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Components/Header/Header.js';
import Home from './Components/Home/Home.js';
import Categories from './Components/Categories/Categories.js';
import About from './Components/About/About.js';
import Contact from './Components/Contact/Contact.js';
import Products from './Components/Products/Products.js';
import Error from './Components/Error/Error.js';
import Product from './Components/Product/Product.js';
import Check from './Components/Check/Check.js';
import Footer from './Components/Footer/Footer.js';

import NavkarContext from './Components/UseContext/NavkarContext.js';

import { Toaster } from 'react-hot-toast';



const RoutePage = () => {
  return (
    <>
      <Router>
        <NavkarContext>
          <Toaster />
          <Header />

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="categories" element={<Categories />} />
            <Route exact path="about" element={<About />} />
            <Route exact path="contact" element={<Contact />} />
            <Route exact path="products" element={<Products />} />
            <Route exact path="product" element={<Product />} />

            <Route exact path="check" element={<Check />} />

            <Route exact path="*" element={<Error />} />
          </Routes>

          <Footer />
        </NavkarContext>
      </Router>
    </>
  )
}

export default RoutePage;