import React, { useState } from 'react';
import Rating from 'react-rating';
import { BaseImgUrl } from '../../Config';
import { reviewbreakpoints } from '../../JsonData.js';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import ProductReviewPopup from '../Popup/ProductReviewPopup.js';





const ProductReview = () => {

  const [showReview, setShowReview] = useState(false);
  const [showReply, setShowReply] = useState(false);

  const [showProductReviewPopup, setShowProductReviewPopup] = useState(false);

  const reviews = [
    {
      profile: `${BaseImgUrl}/customer1.jpg`,
      name: 'Siddharth Dongre',
      review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis accusamus aliquid non vitae adipisci officia odio nostrum est, totam error magnam doloribus molestias animi enim debitis eaque voluptates deleniti mollitia quia maiores consequatur aliquam. Ea accusamus distinctio vitae sint delectus, iste molestiae dignissimos autem, sapiente quibusdam, at esse harum magnam?',
      files: [
        `${BaseImgUrl}/aachar-1.png`,
        `${BaseImgUrl}/aachar-2.png`,
        `${BaseImgUrl}/aachar-3.png`,
      ],
      reply: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores ipsum quod ut consectetur nostrum, numquam sapiente amet asperiores. Cumque aliquam sequi eveniet suscipit, non nihil, aspernatur deleniti eius quos soluta voluptatum praesentium commodi, iste excepturi eos id libero. Id a officiis eveniet voluptates dolorem molestias beatae veritatis eum? Pariatur, sit?'
    },
    {
      profile: `${BaseImgUrl}/customer1.jpg`,
      name: 'Siddharth Dongre',
      review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis accusamus aliquid non vitae adipisci officia odio nostrum est, totam error magnam doloribus molestias animi enim debitis eaque voluptates deleniti mollitia quia maiores consequatur aliquam. Ea accusamus distinctio vitae sint delectus, iste molestiae dignissimos autem, sapiente quibusdam, at esse harum magnam?',
      files: [],
      reply: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores ipsum quod ut consectetur nostrum, numquam sapiente amet asperiores. Cumque aliquam sequi eveniet suscipit, non nihil, aspernatur deleniti eius quos soluta voluptatum praesentium commodi, iste excepturi eos id libero. Id a officiis eveniet voluptates dolorem molestias beatae veritatis eum? Pariatur, sit?'
    },
    {
      profile: `${BaseImgUrl}/customer1.jpg`,
      name: 'Siddharth Dongre',
      review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis accusamus aliquid non vitae adipisci officia odio nostrum est, totam error magnam doloribus molestias animi enim debitis eaque voluptates deleniti mollitia quia maiores consequatur aliquam. Ea accusamus distinctio vitae sint delectus, iste molestiae dignissimos autem, sapiente quibusdam, at esse harum magnam?',
      files: [
        `${BaseImgUrl}/aachar-1.png`,
        `${BaseImgUrl}/aachar-2.png`,
        `${BaseImgUrl}/aachar-3.png`,
      ],
      reply: ''
    },
    {
      profile: `${BaseImgUrl}/customer1.jpg`,
      name: 'Siddharth Dongre',
      review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis accusamus aliquid non vitae adipisci officia odio nostrum est, totam error magnam doloribus molestias animi enim debitis eaque voluptates deleniti mollitia quia maiores consequatur aliquam. Ea accusamus distinctio vitae sint delectus, iste molestiae dignissimos autem, sapiente quibusdam, at esse harum magnam?',
      files: [],
      reply: ''
    },
    {
      profile: `${BaseImgUrl}/customer1.jpg`,
      name: 'Siddharth Dongre',
      review: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis',
      files: [
        `${BaseImgUrl}/aachar-1.png`,
        `${BaseImgUrl}/aachar-2.png`,
        `${BaseImgUrl}/aachar-3.png`,
      ],
      reply: 'Lorem ipsum dolor sit amet consectetur adipisicing elit'
    },
  ];

  return (
    <>
      {/* Product Review Popup */}
      <ProductReviewPopup showProductReviewPopup={showProductReviewPopup} setShowProductReviewPopup={setShowProductReviewPopup} />

      <section className='product-review mt-5 mb-4 mx-auto'>
        <h3 className='text-success mb-4 mb-md-5 mx-4'>Product Review</h3>

        <div className='d-flex flex-column flex-md-row align-items-start justify-content-md-between mx-4'>
          {/* Total Review */}
          <div className='pe-md-4 me-md-4'>
            <h5>Total Review</h5>
            <h3 className='mb-0'>6532</h3>
          </div>

          <hr className='hr w-75 d-block d-md-none' />

          {/* Average Product Rating */}
          <div className='pe-md-4 me-md-4'>
            <h5>Average Product Rating</h5>

            <div className='d-flex align-items-center'>
              <h3 className='mb-0 me-2'>4.7</h3>

              <Rating
                className=''
                initialRating={3.5}
                readonly
                emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '17px' }} alt="empty" />}
                fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '17px' }} alt="fill" />}
              />
            </div>
          </div>

          <hr className='hr w-75 d-block d-md-none' />

          <div className='pe-md-3 me-md-3'>
            <div className='d-flex align-items-center'>
              <img src={`${BaseImgUrl}/icons/rating-2.svg`} alt="rating" />

              <span className='mx-2'>5</span>

              <div className="progress" style={{ height: '13px', width: '150px' }}>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '75%' }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
              </div>

              <span className='ms-3'>5236</span>
            </div>

            <div className='d-flex align-items-center'>
              <img src={`${BaseImgUrl}/icons/rating-2.svg`} alt="rating" />

              <span className='mx-2'>4</span>

              <div className="progress" style={{ height: '13px', width: '150px' }}>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>

              <span className='ms-3'>1356</span>
            </div>

            <div className='d-flex align-items-center'>
              <img src={`${BaseImgUrl}/icons/rating-2.svg`} alt="rating" />

              <span className='mx-2'>3</span>

              <div className="progress" style={{ height: '13px', width: '150px' }}>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
              </div>

              <span className='ms-3'>2536</span>
            </div>

            <div className='d-flex align-items-center'>
              <img src={`${BaseImgUrl}/icons/rating-2.svg`} alt="rating" />

              <span className='mx-2'>2</span>

              <div className="progress" style={{ height: '13px', width: '150px' }}>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '85%' }} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
              </div>

              <span className='ms-3'>3236</span>
            </div>

            <div className='d-flex align-items-center'>
              <img src={`${BaseImgUrl}/icons/rating-2.svg`} alt="rating" />

              <span className='mx-2'>1</span>

              <div className="progress" style={{ height: '13px', width: '150px' }}>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '15%' }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
              </div>

              <span className='ms-3'>1236</span>
            </div>
          </div>

          <hr className='hr d-block d-md-none' />

          <div className='d-flex justify-content-center w-100 d-md-none my-3' onClick={() => setShowProductReviewPopup(true)}>
            <button type="button" className='btn border-warning border-2 text-warning fw-semibold'>Write Review</button>
          </div>

          <div className='d-none d-md-block text-center' onClick={() => setShowProductReviewPopup(true)}>
            <button type="button" className='btn border-warning border-2 text-warning fw-semibold'>Write Review</button>
          </div>
        </div>

        <hr className='hr d-none d-md-block mt-5' />

        <div className=''>
          <Swiper
            className="mySwiper pt-5 pb-4"
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            slidesPerView={1}
            spaceBetween={10}
            autoHeight={true}
            breakpoints={reviewbreakpoints}
            modules={[Pagination]}
          >
            <div className='position-relative'>
              {reviews.map((review, index) => (
                <SwiperSlide className='review_slide bg-white rounded-3 d-flex flex-column align-items-center px-4' key={index}>
                  <div className='review_card shadow-sm pt-5 pb-3'>
                    {/* Profile */}
                    <div className='review_img rounded-pill shadow-sm'>
                      <img className='object-fit-cover' width={70} height={70} src={review.profile} alt="profile" />
                    </div>

                    {/* Name */}
                    <p>{review.name}</p>

                    {/* Rating */}
                    <Rating
                      className=''
                      initialRating={3.4}
                      readonly
                      emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '17px' }} alt="empty" />}
                      fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '17px' }} alt="fill" />}
                    />

                    {/* Review */}
                    <div className='mt-2'>
                      <span>{showReview ? review.review : review.review.split(' ').slice(0, 20).join(' ')}</span>

                      {
                        review.review.split(' ').length > 20
                        &&
                        <span className='text-primary' onClick={() => setShowReview(!showReview)}> show {showReview ? 'less' : 'more'}...</span>
                      }
                    </div>

                    {/* Files */}
                    <>
                      {
                        review.files.length > 0
                        &&
                        <div className='mt-3'>
                          {
                            review.files.map((file, index) => (
                              <div className='d-inline-block me-2 rounded-3' key={index}>
                                <img src={file} className='rounded-3 bg-white shadow-sm' style={{ width: '70px' }} alt={file} />
                              </div>
                            ))
                          }
                        </div>
                      }
                    </>

                    {/* Reply */}
                    <>
                      {
                        review.reply.length > 0
                        &&
                        <div className='bg-success-subtle p-2 rounded-3 mt-3'>
                          <div className='mb-2'>
                            <img src={`${BaseImgUrl}/icons/reply.svg`} className='me-3' alt="reply" />
                            <span className='text-success'>Reply</span>
                          </div>

                          <span>{showReply ? review.reply : review.reply.split(' ').slice(0, 20).join(' ')}</span>

                          {
                            review.reply.split(' ').length > 20
                            &&
                            <span className='text-primary' onClick={() => setShowReply(!showReply)}> show {showReply ? 'less' : 'more'}...</span>
                          }
                        </div>
                      }
                    </>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </section>
    </>
  )
}

export default ProductReview
