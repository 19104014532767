import React, { useContext, useState } from 'react';
import { BaseImgUrl } from '../../Config.js';
import { Link } from 'react-router-dom';
import Rating from 'react-rating';
import { UserContext } from '../UseContext/NavkarContext.js';
import { WhatsappShareButton, TelegramShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import ProductReview from './ProductReview.js';
import RelatedProducts from './RelatedProducts.js';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';



const Product = () => {

  const [isCopy, setIsCopy] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const images = [
    `${BaseImgUrl}/aachar-1.png`,
    `${BaseImgUrl}/sticker2.png`,
    `${BaseImgUrl}/sticker3.png`,
    `${BaseImgUrl}/sticker4.png`,
  ];

  const { navkar } = useContext(UserContext);

  const whatsappText = `product name: aachar,
  product count: 2,
  product size: 100gm,
  `;


  return (
    <>
      <section className='product'>
        {/* Product */}
        <div className='product-details row row-cols-1 row-cols-md-2 mx-auto pt-3 pt-md-5'>
          {/* Product > Images */}
          <div className='product-images px-0 pt-5'>
            {/* Breadcumb */}
            <ol className="breadcrumb d-md-none mx-3 mx-md-4 mx-lg-5">
              <li className="breadcrumb-item"><Link to={`/`} className='text-decoration-none'>Home</Link></li>
              <li className="breadcrumb-item"><Link to={`/`} className='text-decoration-none'>Aachar</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Aam ka aachar</li>
            </ol>

            {/* Header */}
            <div className='d-md-none fs-5 fw-semibold mx-3 mx-md-4 mx-lg-5'>Classic Authentic Mango Pickle In Mustard Oil (Aam Ka Achar)</div>

            {/* Rating */}
            <Rating
              className='d-md-none mx-3 mx-md-4 mx-lg-5 mb-4'
              initialRating={3.4}
              readonly
              emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '15px' }} alt="empty" />}
              fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '15px' }} alt="fill" />}
            />

            {/* Swiper 1 */}
            <Swiper
              className="mySwiper2 product-swiper-1 mx-3 mx-md-4 mx-lg-5 mb-3 rounded-4 bg-white shadow-sm"
              style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
              }}
              spaceBetween={10}
              navigation={true}
              // thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined}
              thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
              modules={[FreeMode, Navigation, Thumbs]}
            >
              {
                images.map((image, index) => (
                  <SwiperSlide className='rounded-4' key={index}>
                    <img src={image} className='h-100 w-100 rounded-4 object-fit-contain' alt='productImage' />
                  </SwiperSlide>
                ))
              }
            </Swiper>

            {/* Swiper 2 */}
            <Swiper
              className="mySwiper product-swiper-2 mx-3 mx-md-4 mx-lg-5 p-1"
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              // touchRatio={1}
              // touchStartPreventDefault={false}
              modules={[FreeMode, Navigation, Thumbs]}
            >
              {
                images.map((image, index) => (
                  <SwiperSlide className='rounded-3 text-center bg-white shadow-sm' key={index}>
                    <img src={image} className='h-100 w-100 rounded-3 object-fit-contain' alt='productImages' />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>

          {/* Product > Details */}
          <div className='product-detail px-3 pt-md-5 overflow-y-scroll scrollbar-hidden'>
            {/* Breadcumb */}
            <ol className="breadcrumb d-none d-md-flex mb-0">
              <li className="breadcrumb-item"><Link to={`/`} className='text-decoration-none'>Home</Link></li>
              <li className="breadcrumb-item"><Link to={`/`} className='text-decoration-none'>Aachar</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Aam ka aachar</li>
            </ol>

            {/* Header */}
            <div className='d-none d-md-block mt-3 mb-1 fs-4 fw-semibold'>Classic Authentic Mango Pickle In Mustard Oil (Aam Ka Achar)</div>

            {/* Rating */}
            <Rating
              className='d-none d-md-block'
              initialRating={3.4}
              readonly
              emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '20px' }} alt="empty" />}
              fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '20px' }} alt="fill" />}
            />

            {/* Price */}
            <div className='my-3'>
              <span className='fs-3 fw-semibold me-3 text-success'>₹220.00</span>
              <span className='fs-5 text-secondary text-decoration-line-through'>₹260.00</span>
            </div>

            <span className='text-secondary'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi perferendis, eligendi error aperiam quidem nisi placeat aut nam quaerat sapiente porro vero eius molestiae distinctio laboriosam omnis nihil impedit illum.</span>

            {/* Size  */}
            <div className='mt-4'>
              <h5 className='text-success'>Size</h5>

              <div className='d-flex'>
                {/* <div className='bg-secondary-subtle text-secondary rounded-3 me-2 me-sm-3 p-2'>100gm</div> */}
                <div className='bg-warning text-white rounded-pill me-2 me-sm-3 px-3 py-2'>200gm</div>
                {/* <div className='border border-warning text-warning rounded-pill me-2 me-sm-3 px-3 py-2'>200gm</div> */}
                {/* <div className='bg-secondary-subtle text-secondary rounded-3 me-2 me-sm-3 p-2'>300gm</div> */}
                {/* <div className='bg-secondary-subtle text-secondary rounded-3 p-2'>400gm</div> */}
              </div>
            </div>

            {/* Order, Call and Share */}
            <div className='d-flex my-4'>
              <div className='border border-success px-3 py-1 rounded-pill' onClick={() => window.open(`https://wa.me/91${navkar.number}?text=${whatsappText}`, '_blank')}>
                <img src={`${BaseImgUrl}/icons/product-order.svg`} className='me-2' alt="order" />
                <span className='text-success'>Order</span>
              </div>

              <div className='bg-success px-3 py-1 mx-2 mx-sm-3 rounded-pill' onClick={() => window.location.href = `tel:${navkar.number}`}>
                <img src={`${BaseImgUrl}/icons/product-call.svg`} className='me-2' alt="call" />
                <span className='text-white'>Call</span>
              </div>

              <div className='border border-success px-3 py-1 rounded-pill' data-bs-toggle="offcanvas" data-bs-target="#productShare" >
                <img src={`https://demo3.sjainventures.com/e-milo/images/icon/share-2.svg`} className='me-2' style={{ width: '15px' }} alt="order" />
                <span className='text-success'>Share</span>
              </div>

              {/* Share > Offcanvas */}
              <div id="productShare" className="offcanvas offcanvas-bottom" tabIndex="-1" aria-labelledby="offcanvasBottomLabel">
                <div className="offcanvas-header">
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <div className="offcanvas-body small d-flex">
                  <CopyToClipboard text={`www.navkarnaturally.in`} onCopy={() => setIsCopy(true)}>
                    <div className='d-flex flex-column align-items-center cursor-pointer me-3'>
                      <div className='border d-inline px-2 py-2 rounded-circle shadow-sm mb-2'>
                        <img src={`${BaseImgUrl}/icons/copy.svg`} style={{ width: '23px' }} alt="copy" />
                      </div>

                      <span>{isCopy ? 'Copied' : 'Copy'}</span>
                    </div>
                  </CopyToClipboard>

                  <WhatsappShareButton title={'Navkar'} url={`www.navkarnaturally.in`} image={`${BaseImgUrl}/logo.png`} className='d-flex flex-column align-items-center me-3 cursor-pointer'>
                    <Link to={`https://web.whatsapp.com/`} className='border d-inline p-2 rounded-circle shadow-sm text-decoration-none mb-2'>
                      <img src={`${BaseImgUrl}/icons/footer-whatsapp.svg`} style={{ width: '23px' }} alt="order" />
                    </Link>

                    <span className='text-dark'>WhatsApp</span>
                  </WhatsappShareButton>

                  <div className='d-flex flex-column align-items-center me-3 cursor-pointer' onClick={() => window.open(`https://www.facebook.com/messages/t/?link=${encodeURIComponent(`url`)}&message=${encodeURIComponent(`message`)}`, '_blank')}>
                    <Link className='border d-inline p-2 rounded-circle shadow-sm text-decoration-none mb-2'>
                      <img src={`${BaseImgUrl}/icons/footer-instagram.svg`} style={{ width: '23px' }} alt="icon" />
                    </Link>

                    <span className='text-dark'>Instagram</span>
                  </div>

                  <div className='d-flex flex-column align-items-center me-3 cursor-pointer' onClick={() => window.open(`https://www.facebook.com/messages/t/?link=${encodeURIComponent(`url`)}&message=${encodeURIComponent(`message`)}`, '_blank')}>
                    <Link className='border d-inline px-3 py-2 rounded-circle shadow-sm text-decoration-none mb-2'>
                      <img src={`${BaseImgUrl}/icons/footer-facebook.svg`} style={{ width: '13px' }} alt="icon" />
                    </Link>

                    <span className='text-dark'>Facebook</span>
                  </div>

                  <TelegramShareButton title={'Navkar'} url={`www.navkarnaturally.in`} className='d-flex flex-column align-items-center me-3 cursor-pointer'>
                    <Link to={`https://web.telegram.org/`} className='border d-inline p-2 rounded-circle shadow-sm text-decoration-none mb-2'>
                      <img src={`${BaseImgUrl}/icons/telegram.svg`} style={{ width: '25px' }} alt="telegram" />
                    </Link>

                    <span className='text-dark'>Telegram</span>
                  </TelegramShareButton>
                </div>
              </div>
            </div>

            {/* Features */}
            <>
              <h5 className='text-success'>Features</h5>

              <hr className='hr bg-success w-75' />

              <div className='d-flex'>
                <div className='me-5'>
                  <div className='fs-6 mb-1 fw-semibold'>Package Information</div>
                  <div className='fs-6 mb-1 fw-semibold'>Diet Type</div>
                  <div className='fs-6 mb-1 fw-semibold'>Package Weight</div>
                  <div className='fs-6 mb-1 fw-semibold'>Flavour</div>
                </div>

                <div className=''>
                  <div className='fs-6 mb-1'>Jar</div>
                  <div className='fs-6 mb-1'>Vegetarian</div>
                  <div className='fs-6 mb-1'>0.48 kg</div>
                  <div className='fs-6 mb-1'>Mango</div>
                </div>
              </div>
            </>

            {/* Description */}
            <div className='my-5'>
              <h5 className='text-success'>Description</h5>

              <hr className='hr bg-success w-75' />

              <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quas autem enim vero minima nobis? Cupiditate hic reprehenderit corrupti distinctio impedit exercitationem, autem ipsam, beatae velit neque mollitia rem magni sit, consectetur asperiores? Numquam ipsum esse dolor, blanditiis ex eos a. Iusto necessitatibus aliquid accusamus temporibus facilis exercitationem. Architecto, nihil repudiandae minima facere nobis consequuntur esse sit aut similique voluptatum reiciendis labore odit magnam nesciunt? Soluta, asperiores? Eaque modi eum odio quia voluptatem sed a vitae?</span>
            </div>

            {/* Ingredients */}
            <div>
              <h5 className='text-success'>Ingredients</h5>

              <hr className='hr bg-success w-75' />

              <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ullam molestias in, saepe corporis assumenda quos nisi iure eligendi commodi. Autem, repellendus beatae sit magnam ducimus ab dolorem minima velit explicabo numquam error quidem iste nobis soluta exercitationem iusto, deserunt porro. Tempora qui dicta officia pariatur velit tempore perspiciatis dolore sint!</span>
            </div>
          </div>
        </div>

        <hr className='hr product-hr-1 mx-auto my-5' />

        {/* Related Products */}
        <RelatedProducts />

        {/* Product Image Ads */}
        <div className='product-images-ads row justify-content-center gap-2 gap-lg-5 text-center pt-4 pt-lg-5 mx-auto'>
          {
            images.map((image, index) => (
              <img src={image} className='product-image-ads bg-white rounded-4 shadow-sm mb-3 mb-md-4 mb-lg-5' alt="productImages" key={index} />
            ))
          }
        </div>

        {/* Product Review */}
        <ProductReview />
      </section>
    </>
  )
}

export default Product;
