import React, { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { BaseApiUrl } from '../../Config';
import axios from 'axios';


export const UserContext = createContext();



const NavkarContext = ({ children }) => {

  const [navkar, setNavkar] = useState({
    number: '9201623579',
    email: 'navkarnaturally@gmail.com',
    address: 'Navkar, Opp. Hari Bhai Gems and Jewellery, Near Shyam Talkies, Budhapara, Raipur Chhattisgarh',
    instagram: 'https://www.instagram.com/navkar_naturally',
    facebook: 'https://www.facebook.com/navkarnaturally',
    youtube: 'https://www.youtube.com/@Navkarnatural',
  });

  const [name, setName] = useState('Siddharth Dongre');

  const navigate = useNavigate();


  // To handle click offcanvas 
  const handleClickOffCanvas = (path) => {
    // It will close offcanvas on click
    window.bootstrap.Offcanvas.getInstance(document.getElementById('offcanvasNavbar'))?.hide();

    // It will navigate to path
    navigate(path);
  };






  // ---------- Customer Review ----------

  const [customerReview, setCustomerReview] = useState({
    profile: '',
    rating: '',
    name: '',
    review: '',
    number: '',
  });

  const [customerReviews, setCustomerReviews] = useState([]);

  const [showCustomerReviewPopup, setShowCustomerReviewPopup] = useState(false);

  const [showCustomerReviewAvtarPopup, setShowCustomerReviewAvtarPopup] = useState(false);

  // To handle change customer review
  const handleChangeCustomerReview = (e) => {
    const { name, value } = e.target;

    setCustomerReview(preState => ({
      ...preState,
      [name]: value
    }));
  };

  // To handle customer review rating
  const handleCustomerReviewRating = (value) => {
    setCustomerReview(preState => ({
      ...preState,
      rating: value
    }));
  };

  // To handle submit customer review
  const handleCustomerReviewSubmit = () => {
    if(customerReview.profile === '' || customerReview.rating === '' || customerReview.name === '' || customerReview.review === '' || customerReview.number === '') {
      toast.error('Please fill the form properly');
    } else {
      axios.post(`${BaseApiUrl}/user/addCustomerReview`, customerReview)
      .then(res => {
        setCustomerReview({
          profile: '',
          name: '',
          rating: '',
          review: '',
          number: '',
          otp: '',
        });

        setShowCustomerReviewPopup(false);

        toast.success(res.data.message);
      }).catch(err => {
        console.log('err : ', err);
      });
    };
  };

  // To get customer reviews
  const getCustomerReviews = () => {
    axios.post(`${BaseApiUrl}/user/getCustomerReviews`)
    .then(res => {
      setCustomerReviews(res.data.customerReviews);
    }).catch(err => {
      console.log('err : ', err);
    });
  };



  // ---------- Contact ----------

  const [contact, setContact] = useState({
    fname: '',
    lname: '',
    email: '',
    number: '',
    message: '',
  });

  console.log('contact : ', contact);

  // To handle contact form submit
  const handleContactSubmit = () => {
    axios.post(`${BaseApiUrl}/user/addContact`, contact)
    .then(res => {
      console.log('res : ', res);

      toast.success(res.data.message);

      setContact({
        fname: '',
        lname: '',
        email: '',
        number: '',
        message: '',
      });
    }).catch(err => {
      console.log('err : ', err);
    });
  };







  const value = {
    navkar, setNavkar,
    name, setName,
    handleClickOffCanvas,
    navigate,


    // ----- Customer Review -----
    customerReviews, setCustomerReviews,
    showCustomerReviewPopup, setShowCustomerReviewPopup,
    customerReview, setCustomerReview,
    showCustomerReviewAvtarPopup, setShowCustomerReviewAvtarPopup,
    handleChangeCustomerReview,
    handleCustomerReviewRating,
    // handleCustomerReviewOtp,
    handleCustomerReviewSubmit,
    getCustomerReviews,

    // ----- Contact -----
    contact, setContact,
    handleContactSubmit,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default NavkarContext
